import {useState} from "react";
import {
    Box,
    Container,
    Grid,
    Typography,
    Alert, InputAdornment, IconButton
} from "@mui/material";
import Logo from "../../../assets/images/logo_chanvrier.png"
import {useLocation, useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {useUserContext} from "../../../context/UserContext";
import TextField from "../../../components/Formik/TextField";
import RedirectButton from "../../../components/Mui/RedirectButton";
import SubmitButton from "../../../components/Formik/SubmitButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

const Login = () => {
    const params = new URLSearchParams(window.location.search);
    const isDisconnected = params.has('disconnected');
    const navigate = useNavigate();
    const location = useLocation();
    const {signIn, storeToken} = useUserContext();

    const INITIAL_FORM_STATE = {
        email: 'noreply@groupe-cyllene.com',
        password: 'Azerty123!'
    }
    const FORM_VALIDATION = Yup.object().shape({
        email: Yup.string().email('Email invalide.').required('E-mail est obligatoire'),
        password: Yup.string().required('Mot de passe est obligatoire'),
    });


    const connect = async (credentials) => {
        const response = await signIn(credentials);
        if (response?.responseData?.token) {
            storeToken(response.responseData['token']);
            if (location.state !== null && !['/login', '/logout'].includes(location.state?.prev)) {
                navigate(location.state.prev, {replace: true});
            } else {
                navigate("/", {replace: true});
            }
            return;
        }
        throw {response}
    }

    const styleTitle = {
        margin: "20px auto",
        color: "#464a53",
        textAlign: "center",
        fontSize: "16px"
    }

    const styleContainerActions = {
        padding: 0,
        display: "flex",
        alignItems: 'center',
        justifyContent: "space-between",
        marginBottom: '20px'
    }

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = (setPassword) => setPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Formik
                initialValues={{...INITIAL_FORM_STATE}}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, {setFieldError, setSubmitting}) => {
                    connect(values)
                        .then(r => {
                        })
                        .catch((err) => {
                            setFieldError('email', err.response.response.data.message);
                            setFieldError('password', err.response.response.data.message);
                        })
                        .finally(() => {
                            setSubmitting(false);
                        })
                }}
            >
                {({isSubmitting}) => (
                    <Form noValidate={true} className={'login'}>
                        <Container component="main" maxWidth="xs">
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Box sx={{
                                    maxWidth: "270px",
                                    margin: "auto",
                                }}>
                                    <img style={{width: '100%'}} src={Logo} alt={"La chanvrière"}/>
                                </Box>
                                {isDisconnected && <Alert className={"alert_logout"} severity="error">Vous avez été déconnecté.</Alert>}
                                <Typography component="h1" variant="h2" sx={styleTitle}>
                                    Connectez-vous à votre compte
                                </Typography>
                                <Box noValidate sx={{width: "100%"}}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Mot de passe"
                                        type={showPassword ? 'text' : 'password'}
                                        id="password"
                                        autoComplete="current-password"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword(setShowPassword)}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                    />
                                    <Grid container sx={styleContainerActions}>
                                        <Grid item>
                                            {/*<FormControlLabel*/}
                                            {/*    control={<Checkbox value="remember" color="primary"/>}*/}
                                            {/*    label="Remember me"*/}
                                            {/*/>*/}
                                        </Grid>
                                        <Grid item>
                                            <RedirectButton to={'/reset-password'} variant={'text'}>Mot de passe
                                                oublié</RedirectButton>
                                        </Grid>
                                    </Grid>
                                    <SubmitButton
                                        type={'submit'}
                                        fullWidth
                                        disabled={isSubmitting}
                                    >
                                        Se connecter
                                    </SubmitButton>

                                </Box>
                            </Box>
                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default Login;